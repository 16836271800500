import React from "react";
import type { ActionFunctionArgs } from "@remix-run/cloudflare";
import { redirect } from "@remix-run/cloudflare";
import { Form, Link } from "@remix-run/react";

import { Button } from "@chords/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@chords/ui/card";
import { Input } from "@chords/ui/input";
import { Label } from "@chords/ui/label";

import { commitSession, getSession } from "~/utils/sessions.ts";
import { getSupabaseWithHeaders } from "~/utils/supabase.server.ts";
import { Container } from "~/components/container.tsx";
import { logError } from "~/log.server.ts";

export const action = async ({ request, context }: ActionFunctionArgs) => {
  const sessionStorage = await getSession(request.headers.get("Cookie"));
  const { supabase } = getSupabaseWithHeaders({ request, context });
  const formData = await request.formData();
  const email = formData.get("email");
  const password = formData.get("password");

  const {
    data: { user, session },
  } = await supabase.auth.signInWithPassword({
    email: email as string,
    password: password as string,
  });

  if (session && user) {
    sessionStorage.set("access_token", session.access_token);
    sessionStorage.set("refresh_token", session.refresh_token);
    sessionStorage.set("current_user", user.id);

    return redirect("/dashboard", {
      headers: {
        "Set-Cookie": await commitSession(sessionStorage),
      },
    });
  }

  logError({
    error: new Error("Failed to login"),
    request,
    context,
    response: new Response(null, { status: 302 }),
  });

  return redirect("/login");
};

export default function Login() {
  return (
    <Container>
      <Card>
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl">Login</CardTitle>
        </CardHeader>
        <CardContent className="grid gap-4">
          <Form method="post">
            <div className="grid gap-6">
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="m@example.com"
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="password">Password</Label>
                <Input id="password" type="password" name="password" />
              </div>
              <Button variant="default" type="submit">
                Login
              </Button>
            </div>
          </Form>
          <p>
            <Link to="/forgot">Forgot password?</Link>
          </p>
        </CardContent>
      </Card>
    </Container>
  );
}
